<template>
  <div class="order-item">
    <div class="head">
      <span>商品号：{{ order.productNum }}</span>
      <span>{{ order.createTime }}</span>
      <span>
        状态：

        <span v-if="order.status === 0" style="color: #409eff">已下架</span>
        <span v-else-if="order.status === 1" style="color: #409eff">
          已上架
        </span>
        <span v-else style="color: #409eff">审核中</span>
      </span>
    </div>
    <div class="body">
      <div class="column goods">
        <ul>
          <li>
            <RouterLink class="image" :to="`/goods/${order.id}`">
              <img
                :src="order.img"
                style="object-fit: cover; width: 70px; height: 70px"
              />
            </RouterLink>
            <div class="info">
              <p class="name ellipsis-2">{{ order.productName }}</p>
              <p class="attr ellipsis">
                <span>材质: {{ order.material }}</span>
                <span>规格: {{ order.formate }}</span>
              </p>
              <p class="attr ellipsis">
                <span v-if="order.tradeType == '2'" class="lable1">保证金</span>
                <span v-else class="lable2">全款</span>
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div class="column state">
        <p>{{ order.warehouseName }}</p>
        <p class="green">
          {{ order.sendFromCityCode }} | {{ order.inventory
          }}{{ order.unitName }}
        </p>
      </div>
      <div class="column amount">
        <p class="red">
          ¥
          <Numeral :value="order.activePrice" format="0,0.00"></Numeral>
          /{{ order.unitName }}
        </p>
        <!-- <p>（含运费：¥{{ order.postFee }}）</p> -->
        <p>最小起订量:{{ order.minSaleCount }}</p>
      </div>
      <div class="column action">
        <p v-if="order.status === 1">
          <!-- 商品已上架 展示下架按钮 -->
          <a @click="down(order.id)">下架</a>
        </p>
        <p v-if="order.status === 0">
          <!-- 商品已上架 展示下架按钮 -->
          <a @click="up(order.id)">上架</a>
        </p>
      </div>
    </div>
  </div>
  <Modal
    v-model="modal"
    class-name="vertical-center-modal"
    title="提示"
    @on-ok="ok"
  >
    <p>{{ modalText }}</p>
  </Modal>
</template>

<script>
import useOrderItem from '@/hooks/member/useOrderItem'
import dayjs from 'dayjs'
import { orderStatus } from '@/api/constants'
// import { ref } from 'vue'
import VueEvent from '@/model/event'
export default {
  name: 'OrderItem',
  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      modal: false, // 提示框
      id: '',
      status: '',
      tradeId: '',
      modalText: '',
    }
  },
  methods: {
    up (id) {
      this.id = id
      this.status = 1
      this.modal = true
      this.modalText = '是否需要上架商品'
    },
    down (id) {
      this.id = id
      this.modal = true
      this.status = 0
      this.modalText = '是否需要下架商品'
    },
    ok () {
      const id = this.id
      const status = this.status
      // 上下架
      this.$store
        .dispatch('UpdateProductStatus', { id, status })
        .then((response) => {
          if (response.returnCode === '1') {
            this.$Message.info('修改成功')
            VueEvent.emit('updateProductStatusMitt', true)
          }
        })
        .catch(() => {})
    },
  },
  destoryed () {
    VueEvent.all.clear()
  },
  setup (props, { emit }) {
    const {
      count,
      onCancelButtonClick,
      onDeleteButtonClick,
      onConfirmReceiptButtonClick,
      onViewLogisticsButtonClick,
    } = useOrderItem(props, emit)

    return {
      count,
      dayjs,
      orderStatus,
      onCancelButtonClick,
      onDeleteButtonClick,
      onConfirmReceiptButtonClick,
      onViewLogisticsButtonClick,
    }
  },
}
</script>

<style lang="less" scoped>
.order-item {
  margin-bottom: 20px;
  border: 1px solid #f5f5f5;
  .head {
    height: 50px;
    line-height: 50px;
    background: #f5f5f5;
    padding: 0 20px;
    overflow: hidden;
    span {
      margin-right: 20px;
      &.down-time {
        margin-right: 0;
        float: right;
        i {
          vertical-align: middle;
          margin-right: 3px;
        }
        b {
          vertical-align: middle;
          font-weight: normal;
        }
      }
    }
    .del {
      margin-right: 0;
      float: right;
      color: #999;
    }
  }
  .body {
    display: flex;
    align-items: stretch;
    .column {
      border-left: 1px solid #f5f5f5;
      text-align: center;
      padding: 20px;
      > p {
        padding-top: 10px;
      }
      &:first-child {
        border-left: none;
      }
      &.goods {
        flex: 3;
        padding: 0;
        align-self: center;
        ul {
          li {
            border-bottom: 1px solid #f5f5f5;
            padding: 10px;
            display: flex;
            &:last-child {
              border-bottom: none;
            }
            .image {
              width: 70px;
              height: 70px;

              border: 1px solid #f5f5f5;
            }
            .info {
              width: 220px;
              text-align: left;
              padding: 0 10px;
              p {
                margin-bottom: 5px;
                &.name {
                  height: 38px;
                }
                &.attr {
                  color: #999;
                  font-size: 12px;
                  span {
                    margin-right: 5px;
                  }
                }
              }
            }
            .price {
              width: 100px;
            }
            .count {
              width: 80px;
            }
          }
        }
      }
      &.state {
        flex: 1;
        .green {
          color: #999;
          font-size: 12px;
          span {
            margin-right: 5px;
          }
        }
      }
      &.amount {
        flex: 1;
        .red {
          color: @priceColor;
        }
      }
      &.action {
        flex: 1;
        text-align: center;
        align-self: center;
        a {
          display: block;
          color: #2d9cdb;
          &:hover {
            color: @xtxColor;
          }
        }
      }
    }
  }
}
.lable1 {
  background: #fff4e3;
  font-size: 11px;
  color: #dc9100;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  border-radius: 4px;
  padding: 2px;
  width: 40px;
  height: 22px;
}
.lable2 {
  background: #ffeae9;
  font-size: 11px;
  color: #d12b23;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  border-radius: 4px;
  padding: 2px;
  width: 40px;
  height: 22px;
}
</style>
