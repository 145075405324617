<template>
  <!-- 概览 -->
  <div class="merchandise-search">
    <div class="search">
      <div class="search-input">
        <label for="">产品名称</label>
        <Input v-model="searchInfo.goodsName" placeholder="请输入产品名称" />
      </div>
      <Button class="btn" @click="search()">查询</Button>
    </div>
  </div>
</template>
<script>
import VueEvent from '@/model/event'
export default {
  name: 'MerchandiseSearch',
  data () {
    return {
      searchInfo: {
        goodsName: '',
      },
    }
  },
	 methods: {
    search () {
			 // 我的商品 搜索栏选项
      VueEvent.emit('merchandiseSearchMitt', this.searchInfo.goodsName)

		}
  }
}
</script>
<style scoped lang="less">
.merchandise-search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  .search {
    display: flex;
    margin: 30px 0;

    .search-input {
      margin-right: 20px;
      display: flex;
      align-items: center;

      label {
        width: 100px;
        text-align: right;
        padding-right: 10px;
        box-sizing: border-box;
      }
    }
  }
  .btn {
    // display: block;
    width: 82px;
    background: #d12b23;
    height: 36px;
    font-weight: 400;
    font-size: 12px;
    color: #fff;
    text-align: center;
    line-height: 40px;
    border: 1px solid #cfcdcd;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
/deep/ .ivu-input {
  border: 1px solid #dcdee2 !important;
  padding: 4px 7px !important;
}
</style>
