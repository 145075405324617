<template>
  <div class="member-merchandise">
    <MerchandisePanel title="我的商品">
      <MerchandiseSearch />
      <!-- 商品筛选组件 -->
      <SubFilter />

      <!-- 订单列表 -->
      <MerchandiseListPage />
    </MerchandisePanel>
  </div>
</template>

<script>
import MerchandisePanel from '@/views/member/merchandise/components/MerchandisePanel'
import SubFilter from '@/views/member/merchandise/components/SubFilter'
import MerchandiseSearch from '@/views/member/merchandise/components/MerchandiseSearch'
import MerchandiseListPage from '@/views/member/merchandise/components/MerchandiseListPage'
export default {
  name: 'MerchandisePage',
  components: {
    MerchandisePanel,
    MerchandiseSearch,
    SubFilter,
    MerchandiseListPage,
  },
  data () {
    return {
      formItem: {
        search: '',
      },

    }
  },
	 created () {
    this.bankCall() // 银行支付回调
  },
	methods: {
    bankCall () {
      if (JSON.stringify(this.$route.query) === '{}') {
        this.$store
          .dispatch('PaymentUpdatePayStatus', this.$route.query)
          .then((response) => {
          })
          .catch(() => {})
      }
    },
	},
  setup () {},
}
</script>

<style lang="less" scoped>
.member-merchandise {
  :deep(.xtx-carousel) .carousel-btn.prev {
    left: 5px;
  }
  :deep(.xtx-carousel) .carousel-btn.next {
    right: 5px;
  }
}
</style>
