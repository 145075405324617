<template>
  <!-- 订单选项卡 -->
  <div class="member-order">
    <div class="body">
      <div class="column goods">品名</div>
      <div class="column state">仓库</div>
      <div class="column amount">单价</div>
      <!-- <div class="column state">日期</div> -->
      <div class="column action">操作</div>
    </div>
    <!-- 订单列表 -->
    <div v-if="orderList" class="order-list">
      <!-- <div v-if="loading" class="loading"></div> -->
      <template v-if="orderList && orderList.rows">
        <MerchandiseItem
          v-for="item in orderList.rows"
          :key="item.id"
          :order="item"
        />
      </template>
      <div v-if="orderList?.rows?.length === 0" class="none">暂无数据</div>
      <!-- <XtxPagination
        v-if="orderList && totalPage > 1"
        v-model:page="reqParams.page"
        :pageSize="reqParams.pageSize"
        :count="totalCount"
      /> -->
    </div>
  </div>
</template>

<script>
import VueEvent from '@/model/event'
import MerchandiseItem from '@/views/member/merchandise/components/MerchandiseItem'
import { ref } from 'vue'
export default {
  name: 'OrderListPage',
  components: { MerchandiseItem },
  data () {
    return {
      orderList: {},
    }
  },
  created () {
    this.init()
  },
  mounted () {
    this.myProduct1FilterMitt()
    this.merchandiseSearchMitt()
    this.updateProductStatusMitt()
  },
  methods: {
    init () {
      this.pageData()
    },
    pageData (productName = '', sendFromCityCode = '') {
      const postData = ref({
        subCategoryId: '',
        productName,
        sendFromCityCode,
        page: 1,
        rows: 10,
      })

      // 获取商品列表
      this.$store
        .dispatch('ProductListWithToken', postData.value)
        .then((response) => {
          this.orderList = response.result
        })
        .catch(() => {})

    },
    myProduct1FilterMitt () {
      let sendFromCityCodeSele = ''
      let productNameSele = ''
      VueEvent.on('myProduct1FilterMitt', (value) => {
        value.forEach((item) => {
          if (item.filterName === '发货地') {
            if (item.selectedFilterName === '全部') {
              sendFromCityCodeSele = ''
            } else {
              sendFromCityCodeSele = item.selectedFilterName
            }
          }

          if (item.filterName === '产品名') {
            if (item.selectedFilterName === '全部') {
              productNameSele = ''
            } else {
              productNameSele = item.selectedFilterName
            }
          }
        })
        this.pageData(productNameSele, sendFromCityCodeSele)

      })
    },
    merchandiseSearchMitt () {
      VueEvent.on('merchandiseSearchMitt', (value) => {
        this.pageData(value)
      })
    },
    updateProductStatusMitt () {
      VueEvent.on('updateProductStatusMitt', (value) => {
        this.pageData()
      })
    },
  },
  beforeUnmount () {
    // console.log('beforeUnmount')
    // VueEvent.all.clear()
  },
  destoryed () {
    VueEvent.all.clear()
  },
  setup () {},
}
</script>

<style lang="less" scoped>
.member-order {
  height: 100%;
  background: #fff;
  position: relative;
}

.order-list {
  background: #fff;
  padding-top: 10px;
  // padding: 20px;
  position: relative;
}

.loading {
  height: calc(100% - 60px);
  width: 100%;
  min-height: 400px;
  position: absolute;
  left: 0;
  top: 60px;
  // background: rgba(255, 255, 255, 0.9) url(../../../assets/images/loading.gif)
  // no-repeat center 18%;
}

.none {
  height: 400px;
  text-align: center;
  line-height: 400px;
  color: #999;
  background: rgba(255, 255, 255, 0.9);
}
.body {
  display: flex;
  align-items: stretch;
  background: #f5f5f5;
  .column {
    border-left: 1px solid #f5f5f5;
    text-align: center;
    padding: 20px;
    > p {
      padding-top: 10px;
    }
    &:first-child {
      border-left: none;
    }
    &.goods {
      flex: 3;
      padding: 10;
      align-self: center;
      text-align: left;
    }
    &.state {
      // width: 200px;
      flex: 1;
      .green {
        color: @xtxColor;
      }
    }
    &.amount {
      flex: 1;
      .red {
        color: @priceColor;
      }
    }
    &.action {
      // width: 140px;
      flex: 1;
      a {
        display: block;
        &:hover {
          color: @xtxColor;
        }
      }
    }
  }
}
</style>
